<script setup>
import { computed, defineEmits, defineProps } from "vue"
import Months from "@/core/Months";

const props = defineProps({
  statistics: {
    type: Array,
    required: true
  },
  active_tab: {
    type: String,
    required: true
  },
  positions: {
    type: Array,
    required: true
  },
  disable_tab_change: {
    type: Boolean,
    required: true
  }
})
const emit = defineEmits([
  'show-details-by-month',
  'tab-change',
]);


const statistics = computed(() => {
  return (props.statistics || []).map(statistic => {
    const monthLabel = (month_index) => {
      try {
        let month = Months.find(i => i.id === month_index)
        return month.label
      } catch (e) {
        return month_index
      }
    }
    return {
      year: statistic.year,
      month: statistic.month,
      month_label: monthLabel(statistic.month),
      order_count: statistic.order_count || 0,
      positions: (statistic.positions || []).map(position => {
        return {
          position: position.position,
          revenue: position.revenue,
          share_of_revenue: position.share_of_revenue,
          target_revenue: position.target_revenue,
          quantity: position.quantity,
          order_count: position.order_count,
        }
      }),
    }
  })
})

const rowPositionsData = (position, key, positions) => {
  try {
    if (position === 'all') {
      return positions.reduce((sum, item) => sum + (parseFloat(item[key]) || 0), 0);
    } else {
      let item = positions.find(i => i.position === position);
      return parseFloat(item[key]) || 0;
    }
  } catch (e) {
    console.log("rowPositionsData")
    console.log(e)
    return 0;
  }
};

const formatPrice = (price) => {
  return parseFloat(price || '0').toLocaleString(undefined, {
    minimumFractionDigits: 2, maximumFractionDigits: 2
  })
}

const targetRevenueDifference = (position, item) => {
  const rev = parseFloat(rowPositionsData(position, 'revenue', item.positions) || '0')
  const targetRev = parseFloat(rowPositionsData(position, 'target_revenue', item.positions) || '0')

  return parseFloat(rev || '0') - parseFloat(targetRev || '0')
}

const targetRevenueDifferenceInPercentage = (position, item) => {
  const rev = parseFloat(rowPositionsData(position, 'revenue', item.positions) || '0')
  const targetRev = parseFloat(rowPositionsData(position, 'target_revenue', item.positions) || '0')

  if (targetRev === 0) return 100;

  return ((rev / targetRev) * 100)
};

const totalStatisticsCount = (position, key) => {
  return statistics.value.reduce((total, item) => {
    return total + parseFloat(rowPositionsData(position, key, item.positions) || 0);
  }, 0);
};

const totalTargetRevenueDifference = (position) => {
  const revenue = totalStatisticsCount(position, 'revenue')
  const target_revenue = totalStatisticsCount(position, 'target_revenue')
  return revenue - target_revenue
}

const totalTargetRevenueDifferenceInPercentage = (position) => {
  const revenue = totalStatisticsCount(position, 'revenue')
  const target_revenue = totalStatisticsCount(position, 'target_revenue')
  if (target_revenue === 0) return 100;
  return ((revenue / target_revenue) - 1) * 100
}

const showDetailsByMonth = (position, item) => {
  emit("show-details-by-month", position, item);
}

const changeTab = (tab) => {
  emit("tab-change", tab);
}
</script>

<template>
  <b-row>
    <b-col lg="12">
      <b-card no-body>

        <b-card-header>
          <b-row class="g-4 align-items-center">
            <b-col sm="auto">
              <div>
                <b-card-title class="mb-0 flex-grow-1">Revenue - {{ active_tab }}</b-card-title>
              </div>
            </b-col>
          </b-row>
        </b-card-header>

        <b-card-header>
          <b-row class="align-items-center">
            <b-col>
              <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0 justify-content-end" role="tablist">
                <template v-for="(position) in positions" :key="`position-tab-${position}`">
                  <li class="nav-item">
                    <span @click="changeTab(position)" :class="{
                      'active': active_tab === position,
                      'disabled': disable_tab_change
                    }" class="nav-link fw-semibold text-capitalize cursor-pointer">
                      {{ position.split('_').join(' ') }}
                    </span>
                  </li>
                </template>
              </ul>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body>
          <div class="table-card gridjs-border-none table-responsive">
            <table role="grid" class="gridjs-table" style="height: auto;">
              <thead>
                <tr>
                  <th class="gridjs-th">
                    Period
                  </th>
                  <th class="gridjs-th">
                    <div class="gridjs-th-content">Orders Quantity</div>
                  </th>
                  <th class="gridjs-th">
                    <div class="gridjs-th-content">Share of revenue</div>
                  </th>
                  <th class="gridjs-th">
                    <div class="gridjs-th-content">Revenue</div>
                  </th>
                  <th class="gridjs-th">
                    <div class="gridjs-th-content">Target revenue</div>
                  </th>
                  <th class="gridjs-th">
                    <div class="gridjs-th-content">Difference</div>
                  </th>
                  <th class="gridjs-th text-center">
                    <div class="gridjs-th-content">Difference in percentage</div>
                  </th>
                  <th class="gridjs-th text-center">
                    <div class="gridjs-th-content">
                      Details
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="gridjs-tbody">
                <template v-for="(item, index) of statistics" :key="index">
                  <tr class="gridjs-tr">
                    <th class="gridjs-th fw-normal">{{ item.month_label }}, {{ item.year }}</th>
                    <td class="gridjs-td fw-normal">
                      {{ rowPositionsData(active_tab, 'order_count', item.positions) }}
                    </td>
                    <td class="gridjs-td">
                      {{ formatPrice(rowPositionsData(active_tab, 'share_of_revenue', item.positions)) }} %
                    </td>
                    <td class="gridjs-td">
                      $ {{ formatPrice(rowPositionsData(active_tab, 'revenue', item.positions)) }}
                    </td>
                    <td class="gridjs-td">
                      $ {{ formatPrice(rowPositionsData(active_tab, 'target_revenue', item.positions)) }}
                    </td>
                    <td class="gridjs-td" :class="{
                      'text-success': targetRevenueDifference(active_tab, item) >= 0,
                      'text-danger': targetRevenueDifference(active_tab, item) < 0,
                    }">
                      $ {{ formatPrice(targetRevenueDifference(active_tab, item)) }}
                    </td>
                    <td class="gridjs-td text-center">
                      <span :class="{
                        'text-success': targetRevenueDifference(active_tab, item) >= 0,
                        'text-danger': targetRevenueDifference(active_tab, item) < 0,
                      }">
                        {{
                          rowPositionsData(active_tab, 'target_revenue', item.positions) === 0
                            ? formatPrice(100)
                            : formatPrice(targetRevenueDifferenceInPercentage(active_tab, item) - 100)
                        }} %
                        <i :class="{
                          'ri-arrow-right-up-line': targetRevenueDifferenceInPercentage(active_tab, item) >= 100,
                          'ri-arrow-right-down-line': targetRevenueDifferenceInPercentage(active_tab, item) < 100,
                        }"></i>
                      </span>
                    </td>
                    <td class="gridjs-td text-center">
                      <b-button @click="showDetailsByMonth(active_tab, item)" variant="outline-primary">
                        Details
                      </b-button>
                    </td>
                  </tr>
                </template>
                <tr class="gridjs-tr">
                  <th class="gridjs-th" colspan="1"></th>
                  <th class="gridjs-th" colspan="1">
                    {{ totalStatisticsCount(active_tab, 'order_count') }}
                  </th>
                  <th class="gridjs-th" colspan="1">
                    {{ formatPrice(totalStatisticsCount(active_tab, 'share_of_revenue') / statistics.length) }}%
                  </th>
                  <th class="gridjs-th" colspan="1">
                    $ {{ formatPrice(totalStatisticsCount(active_tab, 'revenue')) }}
                  </th>
                  <th class="gridjs-th" colspan="1">
                    $ {{ formatPrice(totalStatisticsCount(active_tab, 'target_revenue')) }}
                  </th>
                  <th class="gridjs-th" :class="{
                    'text-success': totalTargetRevenueDifference(active_tab) >= 0,
                    'text-danger': totalTargetRevenueDifference(active_tab) < 0,
                  }" colspan="1">
                    $ {{ formatPrice(totalTargetRevenueDifference(active_tab)) }}
                  </th>
                  <th class="gridjs-th text-center" :class="{
                    'text-success': totalTargetRevenueDifferenceInPercentage(active_tab) >= 0,
                    'text-danger': totalTargetRevenueDifferenceInPercentage(active_tab) < 0,
                  }" colspan="1">
                    {{ formatPrice(totalTargetRevenueDifferenceInPercentage(active_tab)) }}%
                    <i :class="{
                      'ri-arrow-right-up-line': totalTargetRevenueDifferenceInPercentage(active_tab) >= 0,
                      'ri-arrow-right-down-line': totalTargetRevenueDifferenceInPercentage(active_tab) < 0,
                    }"></i>
                  </th>
                  <th class="gridjs-th text-center" colspan="1"></th>
                </tr>
              </tbody>
            </table>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<style scoped></style>