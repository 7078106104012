<script>
import axios from "axios";
import Months from "@/core/Months";
import moment from "moment";
import lodash from "lodash";

export default {
    beforeRouteLeave(to, from, next) {
        alert("here")
        this.modalData.show = false;
        next();
    },
    emits: ['tab-change'],
    props: {
        positions: {
            type: Array,
            required: true
        },
        active_tab: {
            type: String,
            required: true
        },
        disable_tab_change: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            data: [],
            limit: 30,
            currentPage: 1,
            totalCount: 0,
            isLoading: false,
            searchQuery: {
                name: "",
                status: "",
                total_reserved_price: "",
                total_issued_price: "",
                total_payment_price: "",
                balance: "",
            },

            data_fetched_for: {
                year: 2025,
                months: [],
                platforms: []
            },

            months: Months,
            modalData: {
                show: false,
                customer_name: '',
                orders: [],
                status_label: ''
            }
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalCount / this.limit);
        },
    },
    methods: {
        async getData() {
            try {
                this.isLoading = true;

                const months = (this.$route.query.months || "").split(",").filter((i) => i !== "");
                const platforms = (this.$route.query.platforms || "").split(",").filter((i) => i !== "");

                const params = {
                    position: this.active_tab,
                    year: this.$route.query.year,
                    platforms: platforms,
                    limit: this.limit,
                    offset: (this.currentPage - 1) * this.limit,
                    ...Object.fromEntries(
                        Object.entries(this.searchQuery).filter(([_, value]) => {
                            console.log(_)
                            return value !== ""
                        })
                    ),
                };

                if (months.length > 0) {
                    params.months = months;
                }


                const response = await axios.get("/customer/statistics_balance/", { params });

                this.data = response.data.results;
                this.totalCount = response.data.count;

                this.data_fetched_for = {
                    year: this.$route.query.year,
                    months: months,
                    platforms: platforms,
                }
            } catch {
                alert("Error loading customer balances");
            } finally {
                this.isLoading = false;
            }
        },
        goToPage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },
        getMonthLabel(month_index) {
            try {
                let month = this.months.find(i => Number(i.id) === Number(month_index));
                return month.label
            } catch {
                return '//'
            }
        },
        changeTab(new_tab) {
            this.$emit('tab-change', new_tab)
        },
        openModal(customer_name, item) {
            this.modalData.customer_name = customer_name || 'N/Au'
            this.modalData.orders = item.orders || []
            this.modalData.status_label = item.status
            this.modalData.show = true
        },
        daysDifference(start_date) {
            if (!start_date) return null;

            return moment().diff(moment(start_date), 'days');
        },
        getOrderRouteName(child_type) {
            switch (child_type) {
                case "container_order":
                    return "orders/container/view"
                case "wagon_order":
                    return "orders/wagon/view"
                case "wagon_empty_order":
                    return "orders/empty-wagon/view"
            }
        }
    },
    watch: {
        searchQuery: {
            handler: lodash.debounce(function () {
                this.getData();
            }, 1000),
            deep: true,
        },
        currentPage() {
            this.getData();
        },
        active_tab: {
            async handler() {
                await this.getData();
            }
        }
    },
};
</script>

<template>

    <b-modal v-model="modalData.show" :title="modalData.customer_name" hide-footer centered size="lg">
        <div class="mb-3">
            <span class="badge text-capitalize fs-13" :class="{
                'bg-success': modalData.status_label === 'under 30 days',
                'bg-warning': modalData.status_label === '30-60 days',
                'bg-danger': modalData.status_label === '60+ days',
            }">
                {{ modalData.status_label }}
            </span>
        </div>
        <div class="table-responsive">
            <table class="table table-nowrap">
                <thead>
                    <tr>
                        <th scope="col">Order number</th>
                        <th scope="col">Lot number</th>
                        <th scope="col">Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="order in modalData.orders" :key="`order_${order}`">
                        <th scope="row">
                            <a target="_blank" :href="`/${getOrderRouteName(order.child_type)}/${order.order}/`">
                                {{ order.order }}
                            </a>
                        </th>
                        <td>
                            {{ order.lot_number }}
                        </td>
                        <td>
                            {{ order.date }}
                            <span class="text-muted ms-1">({{ daysDifference(order.date) }} days)</span>
                        </td>
                    </tr>
                    <tr v-if="modalData.orders.length === 0">
                        <td colspan="3" class="text-center text-muted">No orders found</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center">
            <b-button @click="modalData.show = false">OK</b-button>
        </div>
    </b-modal>

    <b-card>
        <b-card-header>
            <b-card-title>Customer Balances</b-card-title>
            <div class="mt-3 d-flex justify-content-between align-items-center">
                <div class="d-flex gap-3">
                    <h4 class="mb-0"> <b-badge variant="soft-primary" class="text-primary">{{ data_fetched_for.year
                            }}</b-badge></h4>

                    <div class="d-flex gap-2 border border-top-0 border-bottom-0 border-primary px-4">
                        <h4 v-for="month in data_fetched_for.months" :key="`month_${month}`" class="mb-0"> <b-badge
                                variant="soft-primary" class="text-primary">{{ getMonthLabel(month)
                                }}</b-badge></h4>
                        <template v-if="data_fetched_for.months.length === 0">
                            <h4 class="mb-0">
                                <b-badge variant="soft-primary" class="text-primary">
                                    All Months
                                </b-badge>
                            </h4>
                        </template>
                    </div>

                    <div class="d-flex gap-2">
                        <h4 v-for="platform in data_fetched_for.platforms" :key="`platform_${platform}`" class="mb-0">
                            <b-badge variant="soft-primary" class="text-primary">{{ (platform)
                                }}</b-badge>
                        </h4>
                    </div>
                </div>
                <div class="hstack gap-1">
                    <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0 justify-content-end" role="tablist">
                        <template v-for="(position) in positions" :key="`position-tab-${position}-chart`">
                            <li class="nav-item">
                                <span @click="changeTab(position)" :class="{
                                    'active': active_tab === position,
                                    'disabled': disable_tab_change
                                }" class="nav-link fw-semibold text-capitalize cursor-pointer" data-bs-toggle="tab"
                                    role="tab">
                                    {{ position.split('_').join(' ') }}
                                </span>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </b-card-header>
        <div>
            <div class="table-responsive mt-2">
                <table class="table table-bordered table-nowrap">
                    <thead class="table-light">
                        <tr>
                            <th>Name</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Reserved</th>
                            <th class="text-center">Issued</th>
                            <th class="text-center">Payment</th>
                            <th class="text-center">Balance</th>
                        </tr>
                        <tr>
                            <th>
                                <input :disabled="disable_tab_change" v-model="searchQuery.name"
                                    placeholder="Search by Name" class="form-control" />
                            </th>
                            <th>
                                <select v-model="searchQuery.status" class="form-select" :disabled="disable_tab_change">
                                    <option value="">All</option>
                                    <option value="less_than_30_days">Less than 30 days</option>
                                    <option value="between_30_to_60_days">Between 30 to 60 days</option>
                                    <option value="over_60_days">Over 60 days</option>
                                </select>
                            </th>
                            <th colspan="4">

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td colspan="6" class="text-center py-4">
                                <span class="spinner-border text-primary" role="status"></span> Loading...
                            </td>
                        </tr>
                        <tr v-else v-for="item in data" :key="item.slug">
                            <td>
                                <router-link :to="{
                                    name: 'customer_profile',
                                    params: {
                                        slug: item.slug
                                    }
                                }">
                                    {{ item.name }}
                                </router-link>
                            </td>
                            <td>
                                <div class="d-flex gap-2">
                                    <template v-for="(status_item) in item.statuses"
                                        :key="`status_item_${status_item}`">
                                        <span @click="openModal(item.name, status_item)"
                                            class="badge cursor-pointer fs-11" :class="{
                                                'bg-success': status_item.status === 'under 30 days',
                                                'bg-warning': status_item.status === '30-60 days',
                                                'bg-danger': status_item.status === '60+ days',
                                            }">
                                            {{ status_item.count }}
                                        </span>
                                    </template>
                                </div>
                            </td>
                            <td>${{ parseFloat(item.total_reserved_price).toLocaleString() }}</td>
                            <td>${{ parseFloat(item.total_issued_price).toLocaleString() }}</td>
                            <td>${{ parseFloat(item.total_payment_price).toLocaleString() }}</td>
                            <td :class="{
                                'text-success': parseFloat(item.balance || '0') >= 0,
                                'text-danger': parseFloat(item.balance || '0') < 0
                            }">
                                ${{ parseFloat(item.balance || "0").toLocaleString() }}
                            </td>
                        </tr>
                        <tr v-if="data.length === 0 && !isLoading">
                            <td colspan="6" class="text-center py-4">
                                No Data
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Pagination -->
            <div class="d-flex justify-content-between align-items-center">
                <button class="btn btn-primary" @click="goToPage(currentPage - 1)"
                    :disabled="currentPage === 1 || disable_tab_change">
                    Previous
                </button>

                <div>
                    <span>Page </span>
                    <input type="number" :disabled="disable_tab_change" v-model.number="currentPage"
                        @change="goToPage(currentPage)" min="1" :max="totalPages"
                        class="form-control d-inline-block w-auto text-center" />
                    <span> of {{ totalPages }}</span>
                </div>

                <button class="btn btn-primary" @click="goToPage(currentPage + 1)"
                    :disabled="currentPage === totalPages || disable_tab_change">
                    Next
                </button>
            </div>
        </div>
    </b-card>
</template>
