<script setup>
import PageHeader from "@/components/page-header.vue";
import Filters from "@/views/pages/sales-reports/components/Filters.vue";
import RevenueByMonths from "@/views/pages/sales-reports/components/RevenueByMonths.vue";
import RevenueChart from "@/views/pages/sales-reports/components/RevenueChart.vue";
import axios from "axios";
import { ref } from "vue";
import { useRoute } from "vue-router";
import DetailsByMonthModal from "@/views/pages/sales-reports/components/DetailsByMonthModal.vue";
import CustomerBalances from "@/views/pages/sales-reports/components/CustomerBalances.vue";

const route = useRoute();
const detailsByMonthModal = ref(null);
const customerBalances = ref(null);
let active_tab = ref('all');

const pageHeaderConfig = {
  title: 'Sales Report',
  items: [
    {
      text: "Home",
      href: "/",
    },
    {
      text: "Sales Report",
      active: true,
    },
  ],
}

let revenue_statistics = ref([])
let disable_tab_change = ref(true)
const positions = ref(['all', 'block_train', 'multi_modal', 'rail_forwarder'])
let is_generating_report = ref(false)

const generateReport = async () => {
  is_generating_report.value = true
  try {
    const months = (route.query.months || '').split(',').filter(i => i !== '')
    const platforms = (route.query.platforms || '').split(',').filter(i => i !== '')
    let response = await axios.get('/order/sales_plan/', {
      params: {
        year: route.query.year,
        months: months.length > 0 ? months : undefined,
        platforms: platforms.length > 0 ? platforms : undefined,
      }
    })
    revenue_statistics.value = response.data || []

    if (customerBalances.value) {
      await customerBalances.value.getData();
    }

  } catch {
    revenue_statistics.value = []
    alert("Error getting statistics");
  }

  disable_tab_change.value = false
  is_generating_report.value = false
}

const showDetailsByMonth = (position, item) => {
  if (detailsByMonthModal.value) {
    detailsByMonthModal.value.openModal(position, item);
  }
};

const onTabChange = (tab) => {
  active_tab.value = tab;
}
</script>

<template>
  <PageHeader v-bind="pageHeaderConfig" />

  <Filters @generate-report="generateReport()" :is_generating_report="is_generating_report" />
  <RevenueChart @tab-change="onTabChange" :disable_tab_change="disable_tab_change" :active_tab="active_tab"
    :statistics="revenue_statistics" :positions="positions" />
  <RevenueByMonths @tab-change="onTabChange" :disable_tab_change="disable_tab_change" :statistics="revenue_statistics"
    :active_tab="active_tab" @showDetailsByMonth="showDetailsByMonth" :positions="positions" />
  <DetailsByMonthModal ref="detailsByMonthModal" :disable_tab_change="disable_tab_change" :active_tab="active_tab"
    :positions="positions" />
  <CustomerBalances @tab-change="onTabChange" ref="customerBalances" :disable_tab_change="disable_tab_change"
    :positions="positions" :active_tab="active_tab" />
</template>

<style scoped></style>