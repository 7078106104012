<script>
import {
  CountTo
} from "vue3-count-to";

function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color) {
        color = color.replace(" ", "");
        return color;
      } else return newValue;
    } else {
      var val = value.split(',');
      if (val.length == 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

export default {
  props: {
    statistics: {
      type: Array,
      default: () => [],
      required: true,
    },
    active_tab: {
      type: String,
      default: () => '**',
      required: true,
    },
    positions: {
      type: Array,
      required: true
    },
    disable_tab_change: {
      type: Boolean,
      required: true
    }
  },
  components: {
    CountTo,
  },
  emits: ["tab-change"],
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 370,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "straight",
          dashArray: [0, 0, 8],
          width: [2, 0, 2.2],
        },
        fill: {
          opacity: [0.1, 0.9, 1],
        },
        markers: {
          size: [0, 0, 0],
          strokeWidth: 2,
          hover: {
            size: 4,
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false, // Hides Y-axis numbers
          },
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: 0,
            right: -2,
            bottom: 15,
            left: 10,
          },
        },
        legend: {
          show: true,
          horizontalAlign: "center",
          offsetX: 0,
          offsetY: -5,
          markers: {
            width: 9,
            height: 9,
            radius: 6,
          },
          itemMargin: {
            horizontal: 10,
            vertical: 0,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "30%",
            barHeight: "70%",
          },
        },
        colors: getChartColorsArray('["--vz-primary", "--vz-success", "--vz-danger"]'),
        tooltip: {
          shared: true,
          y: [
            {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$" + y.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                }
                return y;
              },
            },
            {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$" + y.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                }
                return y;
              },
            },
            {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$" + y.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                }
                return y;
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    total_revenue() {
      if (!this.statistics) return 0;
      try {
        return this.statistics.reduce((acc, item) => {
          return acc + item.positions
            .filter(i => this.active_tab === 'all' ? true : i.position === this.active_tab)
            .reduce((sum, position) => sum + parseFloat(position.revenue || '0'), 0);
        }, 0);
      } catch {
        return 0;
      }
    },
    total_target_revenue() {
      if (!this.statistics) return 0;
      try {
        return this.statistics.reduce((acc, item) => {
          return acc + item.positions
            .filter(i => this.active_tab === 'all' ? true : i.position === this.active_tab)
            .reduce((sum, position) => sum + parseFloat(position.target_revenue || '0'), 0);
        }, 0);
      } catch {
        return 0;
      }
    },
  },
  methods: {
    updateSeries() {
      this.series = []

      let paddedStatistics = Array.from({ length: 12 }, (_, index) => ({
        month: index + 1,
        positions: [],
      }));

      this.statistics.forEach(item => {
        if (item.month >= 1 && item.month <= 12) {
          paddedStatistics[item.month - 1] = item;
        }
      });

      this.series = [
        {
          name: "Target Revenue",
          type: "area",
          data: paddedStatistics.map(item => {
            return item.positions
              .filter(i => this.active_tab === 'all' ? true : i.position === this.active_tab)
              .reduce((total, position) => {
                return total + (parseFloat(position.target_revenue) || 0);
              }, 0);
          }),
        },
        {
          name: "Total Revenue",
          type: "bar",
          data: paddedStatistics.map(item => {
            return item.positions
              .filter(i => this.active_tab === 'all' ? true : i.position === this.active_tab)
              .reduce((total, position) => {
                return total + (parseFloat(position.revenue) || 0);
              }, 0);
          }),
        },
        {
          name: "Difference",
          type: "line",
          data: paddedStatistics.map(item => {
            return item.positions
              .filter(i => this.active_tab === 'all' ? true : i.position === this.active_tab)
              .reduce((total, position) => {
                let target = parseFloat(position.target_revenue || '0')
                let revenue = parseFloat(position.revenue || '0')
                return total + (revenue - target);
              }, 0);
          }),
        },
      ]
    },
    changeTab(new_tab) {
      this.$emit('tab-change', new_tab)
    }
  },
  watch: {
    statistics: {
      handler() {
        this.updateSeries()
      }
    },
    active_tab: {
      handler() {
        setTimeout(() => {
          this.updateSeries()
        }, 250)
      }
    }
  }
};

</script>

<template>
  <b-card no-body v-if="series.length > 0">
    <b-card-header class="border-0 align-items-center d-flex">
      <b-card-title class="mb-0 flex-grow-1">Revenue - {{ active_tab.split('_').join(' ') }}</b-card-title>
      <div class="hstack gap-1">
        <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0 justify-content-end" role="tablist">
          <template v-for="(position) in positions" :key="`position-tab-${position}-chart`">
            <li class="nav-item">
              <span @click="changeTab(position)" :class="{
                'active': active_tab === position,
                'disabled': disable_tab_change
              }" class="nav-link fw-semibold text-capitalize cursor-pointer" data-bs-toggle="tab" role="tab">
                {{ position.split('_').join(' ') }}
              </span>
            </li>
          </template>
        </ul>
      </div>
    </b-card-header>

    <b-card-header class="p-0 border-0 bg-soft-light">
      <b-row class="g-0 text-center">
        <b-col cols="6" sm="3">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1">
              $
              <count-to :startVal="0" :endVal="total_revenue" :duration="500" :decimals="2"></count-to>
            </h5>
            <p class="text-muted mb-0">Total revenue</p>
          </div>
        </b-col>
        <b-col cols="6" sm="3">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1">
              $
              <count-to :startVal="0" :endVal="total_target_revenue" :duration="500" :decimals="2"></count-to>
            </h5>
            <p class="text-muted mb-0">Target revenue</p>
          </div>
        </b-col>
        <b-col cols="6" sm="3">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1" :class="{
              'text-success': (total_revenue - total_target_revenue) >= 0,
              'text-danger': (total_revenue - total_target_revenue) < 0,
            }">
              $
              <count-to :startVal="0" :endVal="total_revenue - total_target_revenue" :duration="500"
                :decimals="2"></count-to>
            </h5>
            <p class="text-muted mb-0">Difference in dollars</p>
          </div>
        </b-col>
        <b-col cols="6" sm="3">
          <div class="p-3 border border-dashed border-start-0 border-end-0">
            <h5 class="mb-1" :class="{
              'text-success': (total_revenue - total_target_revenue) >= 0,
              'text-danger': (total_revenue - total_target_revenue) < 0,
            }">
              <i class="align-middle me-1" :class="{
                'ri-arrow-right-up-line': (total_revenue - total_target_revenue) >= 0,
                'ri-arrow-right-down-line': (total_revenue - total_target_revenue) < 0,
              }"></i>
              <count-to v-if="total_target_revenue === 0" :startVal="0" :endVal="100" :duration="500"
                :decimals="2"></count-to>
              <count-to v-else :startVal="0" :endVal="((total_revenue / (total_target_revenue)) - 1) * 100"
                :duration="500" :decimals="2"></count-to>
              %
            </h5>
            <p class="text-muted mb-0">Difference Percentage</p>
          </div>
        </b-col>
      </b-row>
    </b-card-header>

    <b-card-body class="p-0 pb-2">
      <div class="w-100">
        <apexchart class="apex-charts" height="380" type="line" dir="ltr" :series="series" :options="chartOptions">
        </apexchart>
      </div>
    </b-card-body>
  </b-card>
</template>